import { WorkplaceHighlightType } from "./constants";

export interface WorkplaceHighlightLabelOptions {
  chartingSoftware?: string;
  amPatientsPerWorker?: string | number;
  pmPatientsPerWorker?: string | number;
  nocPatientsPerWorker?: string | number;
  cancellationPayoutHours?: string | number;
}

export function getWorkplaceHighlightLabel(
  type: WorkplaceHighlightType,
  options?: WorkplaceHighlightLabelOptions
): string {
  switch (type) {
    case WorkplaceHighlightType.CHARTING_SOFTWARE: {
      // Doesn't make sense to have a charting software highlight without a value.
      // Should never happen, but it's here just to be safe. Same for other highlights below.
      if (!options?.chartingSoftware) {
        return "";
      }

      return `${options.chartingSoftware} software`;
    }

    case WorkplaceHighlightType.AM_PATIENTS_PER_WORKER: {
      if (!options?.amPatientsPerWorker) {
        return "";
      }

      return `AM ~${options.amPatientsPerWorker} patients per worker`;
    }

    case WorkplaceHighlightType.PM_PATIENTS_PER_WORKER: {
      if (!options?.pmPatientsPerWorker) {
        return "";
      }

      return `PM ~${options.pmPatientsPerWorker} patients per worker`;
    }

    case WorkplaceHighlightType.NOC_PATIENTS_PER_WORKER: {
      if (!options?.nocPatientsPerWorker) {
        return "";
      }

      return `NOC ~${options.nocPatientsPerWorker} patients per worker`;
    }

    case WorkplaceHighlightType.CANCELLATION_PAYOUT: {
      if (!options?.cancellationPayoutHours) {
        return "";
      }

      return `Cancellation payout <${options.cancellationPayoutHours}hr`;
    }

    case WorkplaceHighlightType.WELL_SUPPLIED: {
      return "Well supplied";
    }

    case WorkplaceHighlightType.DRESS_CODE: {
      return "Dress code";
    }

    case WorkplaceHighlightType.MANDATORY_BREAKS: {
      return "Mandatory breaks";
    }

    case WorkplaceHighlightType.EXTRA_TIME_PAY: {
      return "Extra time pay";
    }

    default: {
      return "";
    }
  }
}
