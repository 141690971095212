import type { Shift } from "@src/appV2/Shifts/Shift/types";

import { useIsWorkerInstantPayEnabled } from "../useWorkerInstantPayStatus";

export function useIsShiftInstantPay(
  shift: Pick<Shift, "isInstantPay" | "isHCFInstantPayProhibited">
) {
  const isWorkerInstantPayEnabled = useIsWorkerInstantPayEnabled();

  const isShiftInstantPay = shift.isInstantPay ?? !shift.isHCFInstantPayProhibited;

  return isWorkerInstantPayEnabled && isShiftInstantPay;
}
