import { isDefined } from "@clipboard-health/util-ts";
import { matchPath, useLocation } from "react-router-dom";

import { ModalRoute } from "./Navigation/ModalRoute";
import { ModalTransitionSwitch } from "./Navigation/ModalTransitionSwitch";
import {
  SHIFT_DISCOVERY_WORKPLACE_CHECK_IN_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_LEAVE_REVIEW_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH,
} from "./paths";
import { useWorkplaceModalsDataContext } from "./useWorkplaceModalsDataContext";
import { WorkplaceOpenShiftsModal } from "./Workplace/OpenShifts/Modal";
import { WorkplaceCheckInInstructionsModal } from "./Workplace/Policy/CheckInInstructionsModal";
import { WorkplacePoliciesModal } from "./Workplace/Policy/Modal";
import { WorkplaceProfileModal } from "./Workplace/Profile/Modal";
import { WorkplaceCommentModal } from "./Workplace/Review/Comment/Modal";
import { LegacyLeaveReviewContainer } from "./Workplace/Review/LegacyLeaveReviewContainer";
import { WorkplaceReviewsModal } from "./Workplace/Review/Modal";

const modalKeys = [
  SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_CHECK_IN_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_LEAVE_REVIEW_MODAL_PATH,
];

/**
 * Workplace modals are rendered exclusively, so there is only one workplace modal visible at a time.
 * They can be rendered from both list view and map view, with respective base navigation paths
 * - /shift-discovery/list
 * - /shift-discovery/map
 *
 * It is possible that shift modals open on top of workplace modals, which changes the location,
 * but they still belong to the same workplace modal. This is why we need a key that is attached to the
 * currently visible modal rather than using the location.
 *
 * The reason is that shift modals can render on top of workplace modals, which changes the location,
 * while it still belongs to the same workplace modal. This is causing unmounting and remounting of
 * workplace modals when shift modals are opened.
 */
export function WorkplaceModalRoutes() {
  const { workplace, baseNavigationPath } = useWorkplaceModalsDataContext();
  const location = useLocation();

  const modalKey = modalKeys.find((key) =>
    matchPath(location.pathname, {
      path: `${baseNavigationPath}/${key}`,
      exact: true,
    })
  );

  return (
    <ModalTransitionSwitch modalKey={modalKey}>
      <ModalRoute
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplace)) {
            return null;
          }

          return <WorkplaceProfileModal workplace={workplace} modalState={modalState} />;
        }}
      />

      <ModalRoute
        allowNestedPaths
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplace)) {
            return null;
          }

          return <WorkplaceOpenShiftsModal workplace={workplace} modalState={modalState} />;
        }}
      />

      <ModalRoute
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_CHECK_IN_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplace)) {
            return null;
          }

          return (
            <WorkplaceCheckInInstructionsModal workplace={workplace} modalState={modalState} />
          );
        }}
      />

      <ModalRoute
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplace)) {
            return null;
          }

          return <WorkplacePoliciesModal workplace={workplace} modalState={modalState} />;
        }}
      />

      <ModalRoute
        exact
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplace)) {
            return null;
          }

          return <WorkplaceReviewsModal workplace={workplace} modalState={modalState} />;
        }}
      />

      <ModalRoute
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplace)) {
            return null;
          }

          return <WorkplaceCommentModal workplace={workplace} modalState={modalState} />;
        }}
      />

      <ModalRoute
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_WORKPLACE_LEAVE_REVIEW_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplace)) {
            return null;
          }

          return <LegacyLeaveReviewContainer workplace={workplace} modalState={modalState} />;
        }}
      />
    </ModalTransitionSwitch>
  );
}
