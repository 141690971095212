import uniqBy from "lodash/uniqBy";

import { QuestionTag } from "../../../Reviews/types";
import { WorkplaceHighlightType } from "./constants";
import {
  getWorkplaceHighlightLabel,
  type WorkplaceHighlightLabelOptions,
} from "./getWorkplaceHighlightLabel";
import type { WorkplaceHighlight } from "./types";

enum SuppliesAnswer {
  WELL_SUPPLIED = "Most workers have all the supplies they need",
}

enum ChartingSoftware {
  POINT_CLICK_CARE = "PointClickCare",
  MATRIX_CARE = "MatrixCare",
  AMERICAN_HEALTH_TECH = "American HealthTech",
  CARE_VOYANT = "CareVoyant",
}

const answerByChartingSoftware: Record<ChartingSoftware, string> = {
  [ChartingSoftware.POINT_CLICK_CARE]: "The charting software is PointClickCare",
  [ChartingSoftware.MATRIX_CARE]: "The charting software is MatrixCare",
  [ChartingSoftware.AMERICAN_HEALTH_TECH]: "The charting software is American HealthTech",
  [ChartingSoftware.CARE_VOYANT]: "The charting software is CareVoyant",
};

enum PatientsPerNursePeriod {
  AM = "AM",
  PM = "PM",
  NOC = "NOC",
}

interface PatientsPerNurse {
  period: PatientsPerNursePeriod;
  value: string;
}

const highlightTypeByPeriod: Record<PatientsPerNursePeriod, WorkplaceHighlightType> = {
  [PatientsPerNursePeriod.AM]: WorkplaceHighlightType.AM_PATIENTS_PER_WORKER,
  [PatientsPerNursePeriod.PM]: WorkplaceHighlightType.PM_PATIENTS_PER_WORKER,
  [PatientsPerNursePeriod.NOC]: WorkplaceHighlightType.NOC_PATIENTS_PER_WORKER,
};

const highlightLabelOptionByPeriod: Record<
  PatientsPerNursePeriod,
  keyof WorkplaceHighlightLabelOptions
> = {
  [PatientsPerNursePeriod.AM]: "amPatientsPerWorker",
  [PatientsPerNursePeriod.PM]: "pmPatientsPerWorker",
  [PatientsPerNursePeriod.NOC]: "nocPatientsPerWorker",
};

const answerPatternByPatientsPerNursePeriod: Record<PatientsPerNursePeriod, string> = {
  [PatientsPerNursePeriod.AM]: "AM: There were __VALUE__ patients per nurse",
  [PatientsPerNursePeriod.PM]: "PM: There were __VALUE__ patients per nurse",
  [PatientsPerNursePeriod.NOC]: "NOC: There were __VALUE__ patients per nurse",
};

const getPatientsPerNurseFromAnswer = (answer: string): PatientsPerNurse[] => {
  const patientsPerNurseRatios: PatientsPerNurse[] = [];

  // Split by comma to handle combined ratios
  const segments = answer.split(",").map((segment) => segment.trim());

  segments.forEach((segment) => {
    Object.values(PatientsPerNursePeriod).forEach((period) => {
      const answerPattern = answerPatternByPatientsPerNursePeriod[period];
      const [startOfAnswerPattern, endOfAnswerPattern] = answerPattern.split("__VALUE__");

      if (segment.startsWith(startOfAnswerPattern)) {
        // For "AM: There were 8 patients per nurse", the value is "8"
        const value = segment
          .replace(startOfAnswerPattern, "")
          .replace(endOfAnswerPattern, "")
          .trim();

        patientsPerNurseRatios.push({
          period,
          value,
        });
      }
    });
  });

  return patientsPerNurseRatios;
};

export interface AggregatedReviews {
  tags: QuestionTag[];
  text: string;
}

interface BuildWorkplaceReviewHighlightsProps {
  aggregatedReviews: AggregatedReviews[];
}

export function buildWorkplaceReviewHighlights({
  aggregatedReviews,
}: BuildWorkplaceReviewHighlightsProps): WorkplaceHighlight[] {
  const highlightTypes: WorkplaceHighlightType[] = [];
  const highlightLabelOptions: WorkplaceHighlightLabelOptions = {};

  if (aggregatedReviews.length === 0) {
    return [];
  }

  const findReviewsForTag = (questionTag: QuestionTag) => {
    return aggregatedReviews.filter((aggregatedReview) =>
      aggregatedReview.tags.includes(questionTag)
    );
  };

  const findChartingSoftwareFromReviews = (): ChartingSoftware | undefined => {
    const chartingSoftwareReview = findReviewsForTag(QuestionTag.SOFTWARE)?.[0];
    if (!chartingSoftwareReview) {
      return undefined;
    }

    return Object.values(ChartingSoftware).find(
      (value) => answerByChartingSoftware[value] === chartingSoftwareReview.text
    );
  };

  const findPatientsPerNurseRatiosFromReviews = (): PatientsPerNurse[] => {
    const patientsPerNurseReviews = findReviewsForTag(QuestionTag.PATIENTS_PER_NURSE);
    if (patientsPerNurseReviews.length === 0) {
      return [];
    }

    // In case the same period is mentioned multiple times, we want to deduplicate. It will use the last found value.
    return uniqBy(
      patientsPerNurseReviews.flatMap((review) => getPatientsPerNurseFromAnswer(review.text)),
      "period"
    );
  };

  const chartingSoftware = findChartingSoftwareFromReviews();
  if (chartingSoftware) {
    highlightTypes.push(WorkplaceHighlightType.CHARTING_SOFTWARE);
    highlightLabelOptions.chartingSoftware = chartingSoftware;
  }

  const patientsPerNurseRatios = findPatientsPerNurseRatiosFromReviews();
  if (patientsPerNurseRatios.length > 0) {
    patientsPerNurseRatios.forEach((patientsPerNurseRatio) => {
      const highlightType = highlightTypeByPeriod[patientsPerNurseRatio.period];
      const labelOptionKey = highlightLabelOptionByPeriod[patientsPerNurseRatio.period];

      highlightTypes.push(highlightType);
      highlightLabelOptions[labelOptionKey] = patientsPerNurseRatio.value;
    });
  }

  const suppliesReview = findReviewsForTag(QuestionTag.SUPPLIES)?.[0];
  if (suppliesReview?.text === SuppliesAnswer.WELL_SUPPLIED) {
    highlightTypes.push(WorkplaceHighlightType.WELL_SUPPLIED);
  }

  return highlightTypes.map((type) => ({
    type,
    label: getWorkplaceHighlightLabel(type, highlightLabelOptions),
  }));
}
