import { isDefined } from "@clipboard-health/util-ts";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { useGetExtraTimePaySettings } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";
import { type Facility } from "@src/appV2/Facilities/types";

import { buildWorkplacePolicyHighlights } from "./buildWorkplacePolicyHighlights";
import type { WorkplaceHighlight } from "./types";

interface UseWorkplacePolicyHighlightsProps {
  workplaceId: string | undefined;
  lateCancellation: Facility["lateCancellation"];
  requiresLunchBreak: Facility["requiresLunchBreak"];
}

interface UseWorkplacePolicyHighlightsResult {
  highlights: WorkplaceHighlight[];
  isLoading: boolean;
}

export const useWorkplacePolicyHighlights = (
  props: UseWorkplacePolicyHighlightsProps
): UseWorkplacePolicyHighlightsResult => {
  const { workplaceId, lateCancellation, requiresLunchBreak } = props;

  const { data: workplaceNotes = [], isLoading: isWorkplaceNotesLoading } = useFacilityNotes(
    workplaceId ?? "",
    {
      enabled: isDefined(workplaceId),
      useErrorBoundary: false,
    }
  );

  const { data: extraTimePaySettings, isLoading: isExtraTimePayLoading } =
    useGetExtraTimePaySettings(workplaceId);

  const visibleNoteIdentifiers = workplaceNotes
    .filter((note) => note.note && note.isVisibleToWorker)
    .map((note) => note.identifier);

  const isExtraTimePayEnabled =
    isDefined(extraTimePaySettings?.workdayOptions) ||
    isDefined(extraTimePaySettings?.weeklyOption);

  return {
    isLoading: isWorkplaceNotesLoading || isExtraTimePayLoading,
    highlights: buildWorkplacePolicyHighlights({
      isExtraTimePayEnabled,
      lateCancellation,
      requiresLunchBreak,
      workplaceNoteIdentifiers: visibleNoteIdentifiers,
    }),
  };
};
