export enum WorkplaceHighlightType {
  AM_PATIENTS_PER_WORKER = "am-patients-per-worker",
  NOC_PATIENTS_PER_WORKER = "noc-patients-per-worker",
  PM_PATIENTS_PER_WORKER = "pm-patients-per-worker",
  CANCELLATION_PAYOUT = "cancellation-payout",
  CHARTING_SOFTWARE = "charting-software",
  DRESS_CODE = "dress-code",
  EXTRA_TIME_PAY = "extra-time-pay",
  MANDATORY_BREAKS = "mandatory-breaks",
  WELL_SUPPLIED = "well-supplied",
}
