/* eslint-disable @typescript-eslint/ban-types */
import { isDefined } from "@clipboard-health/util-ts";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { isFuture, parseISO } from "date-fns";

import { ShiftType } from "./constants";

interface ResolveShiftTypeProps {
  urgency?: string | number | null;
  window?: string | null;
  priorityTill?: string | null;
}

export function resolveShiftType({
  urgency,
  window,
  priorityTill,
}: ResolveShiftTypeProps): ShiftType {
  if (isDefined(urgency)) {
    return ShiftType.URGENT;
  }

  if (window === ShiftWindow.A_TEAM) {
    return ShiftType.PRIORITY;
  }

  if (isDefined(priorityTill) && isFuture(parseISO(priorityTill))) {
    return ShiftType.PRIORITY;
  }

  return ShiftType.REGULAR;
}
/* eslint-enable @typescript-eslint/ban-types */
