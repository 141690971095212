import { isDefined } from "@clipboard-health/util-ts";
import type { Facility, FacilityNote } from "@src/appV2/Facilities/types";

import { WorkplaceNoteIdentifier } from "../constants";
import { WorkplaceHighlightType } from "./constants";
import {
  getWorkplaceHighlightLabel,
  type WorkplaceHighlightLabelOptions,
} from "./getWorkplaceHighlightLabel";
import type { WorkplaceHighlight } from "./types";

interface BuildWorkplacePolicyHighlightsProps {
  isExtraTimePayEnabled: boolean;
  lateCancellation: Facility["lateCancellation"];
  requiresLunchBreak: Facility["requiresLunchBreak"];
  workplaceNoteIdentifiers: Array<FacilityNote["identifier"]>;
}

export const buildWorkplacePolicyHighlights = ({
  isExtraTimePayEnabled,
  lateCancellation,
  requiresLunchBreak,
  workplaceNoteIdentifiers = [],
}: BuildWorkplacePolicyHighlightsProps): WorkplaceHighlight[] => {
  const highlightTypes: WorkplaceHighlightType[] = [];
  const highlightLabelOptions: WorkplaceHighlightLabelOptions = {};

  if (isExtraTimePayEnabled) {
    highlightTypes.push(WorkplaceHighlightType.EXTRA_TIME_PAY);
  }

  const offersCancellationPayout =
    isDefined(lateCancellation) && lateCancellation.period > 0 && lateCancellation.feeHours > 0;

  if (offersCancellationPayout) {
    highlightTypes.push(WorkplaceHighlightType.CANCELLATION_PAYOUT);
    highlightLabelOptions.cancellationPayoutHours = lateCancellation.period;
  }

  const hasNote = (identifier: WorkplaceNoteIdentifier): boolean => {
    return workplaceNoteIdentifiers.includes(identifier);
  };

  if (requiresLunchBreak && hasNote(WorkplaceNoteIdentifier.MANDATORY_BREAK_POLICY)) {
    highlightTypes.push(WorkplaceHighlightType.MANDATORY_BREAKS);
  }

  if (hasNote(WorkplaceNoteIdentifier.DRESS_CODE_INSTRUCTIONS)) {
    highlightTypes.push(WorkplaceHighlightType.DRESS_CODE);
  }

  return highlightTypes.map((type) => ({
    type,
    label: getWorkplaceHighlightLabel(type, highlightLabelOptions),
  }));
};
