import { isDefined } from "@clipboard-health/util-ts";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";

import { type ShiftFormattedPayBreakdown } from "./types";

export function getFormattedShiftPayBreakdown(
  offerPay?: number,
  finalPay?: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  originalAmount?: number | null
): ShiftFormattedPayBreakdown | undefined {
  const hourlyPayValue = offerPay ?? finalPay;

  if (!isDefined(hourlyPayValue)) {
    return undefined;
  }

  if (!isDefined(originalAmount)) {
    return undefined;
  }

  return {
    totalPay: formatDollarsAsUsd(originalAmount),
    hourlyPay: formatDollarsAsUsd(hourlyPayValue),
  };
}
